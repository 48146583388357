const dismissNotification = (element) => {
  element.classList.remove('custom-show');
  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(() => {
      element.classList.add('custom-hide');

      element.addEventListener('animationend', () => {
        element.parentNode.removeChild(element);
      }, { once: true });
    });
  });
}


/*
 * options is an object with these keys: 
 *
 * classes - The CSS classes you would like the container div to have, for example
 *   a Bootstrap class like 'alert-danger'.  The 'ihod-alert' class customizes the
 *   Bootstrap alert and adds animation.  The alert contains these classes by default:
 *   'alert custom-show alert-dismissible fade show border-right-0 border-left-0 rounded-0'.
 * 
 * message - Whatever text you would like the alert to display.
 *   Default is an empty string.
 * 
 * container - A DOM element.  The element you would like to append the alert to.
 *   Defaults to document.body.
 * 
 * time - A number which is the time in milliseconds that the alert should persist.
 *   an empty string may be used if the alert should remain persistent.
 *   Defaults to 2000.
 * 
 * 
 * Usage:
 * 
 * alert.init({
 *   classes: 'alert-danger ihod-alert',
 *   message: "<strong>I'm sorry there was a problem with that request.</strong>",
 *   container: document.querySelector('.header'),
 *   time: 4000 // or '' 
 * });
*/
const init = (options) => {
  const { time = 2000, container = document.body, classes = '', message = '' } = options;
  let st;
  let notificationElement = `
    <div class="alert ${classes} custom-show alert-dismissible fade show border-right-0 border-left-0 rounded-0" role="alert">
      ${message}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  `;

  // add element to DOM
  container.insertAdjacentHTML('afterbegin', notificationElement);

  // remove from DOM after certain time period if it has not already been dismissed
  notificationElement = document.querySelector('.alert.custom-show');

  // remove from DOM after certain time period if it has not already been dismissed
  if (notificationElement && time) {
    st = setTimeout(() => {
      clearTimeout(st);
      dismissNotification(notificationElement);
    }, time);
  }
}

/*
  Wrapper that lets you pass in just a msg and a msgClass, with reasonable
  defaults for everything else.

  Usage:

  const errMsgClass = 'alert-danger';
  ...
  alert.tellUser('No query criteria selected.', errMsgClass);
*/
const tellUser = (msg, msgClass) => {
  init({
    classes: `${msgClass} ihod-alert`,
    message: msg,
    time: 4000
  });
}

export {
  init,
  tellUser
}