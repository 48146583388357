import ClipboardJS from 'clipboard';
import 'popper.js';
import * as alert from '../utils/alert';
const settingsModal = document.getElementById('settings-modal');
const $settingsModal = $(settingsModal);
const saveSettingsButton = document.getElementById(
  'iHODSettingsSavePreferences'
);
const smsInput = document.getElementById('ihodSettingsSMSInput');
const emailInput = document.getElementById('ihodSettingsEmailInput');
const billSettingsForm = document.getElementById('bill-settings-form');
const fieldsetToggles = billSettingsForm.querySelectorAll(
  '.inputSegment.toggleColor'
);

const updateCalendarUrl = (e) => {
  const urlHolder = document.getElementById('ihodSettingsApiUrl');
  const startingUrl = urlHolder.value.trim();
  const urlBase = startingUrl.slice(0, startingUrl.indexOf('?') + 1);
  const calendarChecks = document.querySelectorAll(
    '.ihodSettingsCalendarCheck'
  );
  let params = Array.from(
    calendarChecks,
    (check) => `${check.value}=${check.checked ? 'Y' : 'N'}`
  );
  let url = `${urlBase}${params.join('&')}`;
  urlHolder.value = url;
};

const initCopyJs = () => {
  const clipboard = new ClipboardJS('.urlCopy');
  clipboard.on('success', function (e) {
    console.info('Action:', e.action);
    $('.urlCopy').tooltip();
    e.clearSelection();
  });
};

const highlightFieldset = (e) => {
  const fieldsetToggle = e.target.closest('.inputSegment.toggleColor');
  fieldsetToggles.forEach(
    (presentFieldset) => (presentFieldset.style.backgroundColor = '#fff')
  );
  if (e.target.checked == true) {
    fieldsetToggle.style.backgroundColor = '#43858e46';
  }
};

const hideSaveButtonOnCalendarTab = () => {
  saveSettingsButton.style.display = 'none';
};

const showSaveButtonOnCalendarTab = () => {
  saveSettingsButton.style.display = 'inline-block';
};

const removeErrorFeedback = () => {
  const modalAlert = settingsModal.querySelector('.modal-alert');
  if (modalAlert) modalAlert.parentNode.removeChild(modalAlert);

  smsInput.classList.remove('is-invalid');
  emailInput.classList.remove('is-invalid');
};

const updateSettingsModal = (data) => {
  const billSettingsMap = {
    'bill text': 'bill-text-radio',
    'bill history': 'bill-history-radio',
    'bill highlight': 'bill-highlight-radio',
  };
  const agendaDisplayMap = {
    'bill summaries': 'settings-sum-check',
    'impact statements': 'settings-impact-check',
    'last 5 actions': 'settings-last-check',
    'senate committee actions': 'settings-senate-check',
    subcommittee: 'settings-sub-action-check',
    'subcommittee recommendation': 'settings-sub-rec-check',
  };
  let {
    display_content_defaults: displayArr,
    bill_preference: billPref,
    mem_data: memDataObj,
  } = data;
  let email, sms, billRadio, display, displayCheck;

  // update phone and email inputs if we have the memdata info
  if (memDataObj !== null) {
    ({ email_other: email, sms } = memDataObj);
    smsInput.value = sms;
    emailInput.value = email;
  }
  //var billPref = data["bill_preference"].toLowerCase();
  // update bill radios
  billPref = billPref.toLowerCase();
  billRadio = document.querySelector(
    `#bill-settings-tab #${billSettingsMap[billPref]}`
  );
  billRadio.checked = true;

  // update agenda block display checkboxes
  for (let i = 0; i < displayArr.length; i++) {
    display = displayArr[i].toLowerCase();
    displayCheck = document.querySelector(
      `#agenda-settings-tab #${agendaDisplayMap[display]}`
    );
    displayCheck.checked = true;
  }
};

const submitSettings = (route, data) => {
  return $.get(route, data, null, 'json');
};

const fetchSettingsData = () => {
  const route = `${window.location.protocol}//${window.location.host}/users/settings_menu.json`;
  return $.getJSON(route);
};

const handleSaveSettingsButton = () => {
  const selectedBillPref = `pref=${
    document.querySelector('.ihodSettingsBillRadio:checked').value
  }`;
  const agendaPrefInputs = document.querySelectorAll(
    '.ihodSettingsAgendaCheck:checked'
  );
  const agendaPrefs = `bill_content_pref=${Array.from(
    agendaPrefInputs,
    (check) => check.value
  ).join(',')}`;
  const patronAlsoNotificationInput = document.getElementById(
    'ihodSettingsPatronAlsoCheck'
  );
  const noticepref = `notice_pref=${
    patronAlsoNotificationInput.checked ? patronAlsoNotificationInput.value : ''
  }`;
  const sms = `sms=${smsInput.value}`;
  const email = `emailother=${emailInput.value}`;
  const route = `${window.location.protocol}//${window.location.host}/user/update_profile?${selectedBillPref}&${agendaPrefs}&${noticepref}&${sms}&${email}`;

  const successCB = (data) => {
    const { status, results } = data;
    const { notification: notificationObj } = results;
    let message;

    // All good
    if (status === 1) {
      $settingsModal.modal('hide');

      alert.init({
        classes: 'alert-success ihod-alert',
        message: '<strong>Your settings have been saved.</strong>',
        time: 4000,
      });

      return;
    }
    // Error
    if (status === 0) {
      // notification error
      if (notificationObj.status === 0) {
        message = notificationObj.error;
        $('#notificationIhodSettingsButton').tab('show');

        //sms error
        if (notificationObj.sms_error === 1) {
          smsInput.classList.add('is-invalid');
        }

        // email error
        if (notificationObj.email_error === 1) {
          emailInput.classList.add('is-invalid');
        }
      } else {
        message = 'An error occurred.';
      }

      alert.init({
        classes: 'alert-danger modal-alert',
        message: `<strong>${message}</strong>`,
        container: settingsModal.querySelector('.modal-body'),
        time: '',
      });
    }
  };

  const errorCB = () => {
    console.log("I'm sorry but an error occurred.");
  };

  removeErrorFeedback();

  submitSettings(route).done(successCB).fail(errorCB);
};

const handleSettingsModal = (e) => {
  const successCB = (data) => {
    updateSettingsModal(data);
  };

  const errorCB = () => {
    console.log("I'm sorry but an error occurred.");
  };

  fetchSettingsData().done(successCB).fail(errorCB);
};

const setupEventListeners = () => {
  $settingsModal.on('show.bs.modal', handleSettingsModal);
  $settingsModal.on('hide.bs.modal', removeErrorFeedback);
  $('#settings-modal #calendarIhodSettingsButton').on(
    'show.bs.tab',
    hideSaveButtonOnCalendarTab
  );
  $('#settings-modal #calendarIhodSettingsButton').on(
    'hide.bs.tab',
    showSaveButtonOnCalendarTab
  );
  document
    .getElementById('ihodSettingsCalendarForm')
    .addEventListener('change', updateCalendarUrl);
  saveSettingsButton.addEventListener('click', handleSaveSettingsButton);
  billSettingsForm.addEventListener('change', highlightFieldset);
};

const init = () => {
  setupEventListeners();
  initCopyJs();
};

init();
